<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <div :class="$style.container">
        <div :class="$style.search">
          <Autocomplete
            :search="query.search"
            placeholder="Поиск"
            :valueNames="['name']"
            @querySearch="querySearch"
            @handleFindItems="getSeosList"
            @selectItem="handleUpdateSeo($event.id)"
            @input="query.search = $event"
          />
          <el-button
            :class="$style.searchButton"
            type="primary"
            @click="getSeosList"
          >
            Показать
          </el-button>
        </div>
        <div :class="$style.filters">
          <el-button
            type="primary"
            :class="$style.openButton"
            @click="isFiltersOpen = !isFiltersOpen"
            >Фильтры</el-button
          >
          <Dropdown
            :isOpenDropDown="isFiltersOpen"
            title="Фильтры seo-шаблонов"
            :class="$style.dropdown"
            @submitFilters="submitFilters()"
            @resetFilters="resetFilters()"
          >
            <div :class="$style.filter">
              <p>Сущность:</p>
              <el-select
                :class="$style.select"
                v-model="filters.entity"
                placeholder="Выберите сущность"
              >
                <el-option
                  v-for="(item, index) in $options.SEO_ENTITIES"
                  :key="item + index"
                  :label="$options.SEO_ENTITIES_TO_DISPLAY[item] ?? item"
                  :value="index"
                >
                </el-option>
              </el-select>
            </div>
            <div :class="$style.filter">
              <p>Тип:</p>
              <el-select
                :class="$style.select"
                v-model="filters.type"
                placeholder="Тип"
              >
                <el-option
                  v-for="(item, index) in $options.SEO_TYPES"
                  :key="item + index"
                  :label="$options.SEO_TYPES_TO_DISPLAY[item] ?? item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </Dropdown>
        </div>
      </div>
      <el-button type="primary" @click="handleCreateSeo"
        >Создать seo-шаблон</el-button
      >
    </div>
    <el-table :data="seos" stripe>
      <el-table-column prop="title" label="Название" sortable />
      <el-table-column prop="entity" abel="Сущность">
        <template slot-scope="scope">
          {{ $options.SEO_ENTITIES_TO_DISPLAY[scope.row?.entity] ?? '' }}
        </template>
      </el-table-column>
      <el-table-column width="100">
        <template slot-scope="scope">
          <ActionButtons
            editModal
            @delete="deleteSeo(scope?.row)"
            @edit="handleUpdateSeo(scope?.row?.id)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="totalPage > 1"
      background
      layout="prev, pager, next"
      :page-size="query.limit"
      :total="count"
      :current-page.sync="query.page"
      :class="$style.pagination"
      @current-change="getSeosList"
    />
    <el-dialog
      :visible.sync="isModalOpen"
      show-close
      :class="$style.dialog"
      :title="
        currentModalType === $options.MODAL_TYPES.CREATE
          ? 'Создание seo-шаблона'
          : 'Редактирование seo-шаблона'
      "
      :before-close="handleCloseForm"
    >
      <p :class="$style.dialogInfo">
        Переменные указываются в фигурных скобках (например
        <code>{title}</code>, где title - заголовок страницы,
        <code>{description}</code> - описание, <code>{og_title}</code> -
        заголовок для соц.сетей, <code>{og_description}</code> - описание для
        соц.сетей, <code>{keywords}</code> - ключевые слова).
      </p>
      <el-form
        ref="form"
        :model="form"
        :class="$style.form"
        label-position="left"
        label-width="12.5rem"
        :rules="rules"
      >
        <el-form-item label="Сущность" prop="seo.entity">
          <el-select
            :class="$style.select"
            v-model="form.seo.entity"
            placeholder="Выберите сущность"
          >
            <el-option
              v-for="(item, index) in $options.SEO_ENTITIES"
              :key="item + index"
              :label="$options.SEO_ENTITIES_TO_DISPLAY[item]"
              :value="index"
            >
            </el-option> </el-select
        ></el-form-item>
        <SeoBlock
          is-with-keywords
          is-add-event
          :title.sync="form.seo.title"
          :description.sync="form.seo.description"
          :og-title.sync="form.seo.ogTitle"
          :og-description.sync="form.seo.ogDescription"
          :og-image.sync="form.seo.ogImage.origin"
          :keywords.sync="form.seo.keywords"
          :class="$style.seo"
        />
      </el-form>
      <el-button
        type="primary"
        :class="$style.createButton"
        @click="submitForm"
        >{{
          currentModalType === $options.MODAL_TYPES.CREATE
            ? 'Создать'
            : 'Редактировать'
        }}</el-button
      >
    </el-dialog>
  </div>
</template>
<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete.vue'
import SeoBlock from '@/components/moleculs/SEO.vue'
import Dropdown from '@/components/atoms/Dropdown'

import { MODAL_TYPES, SORTING_TYPES } from '@/constants/common'
import {
  SEO_ENTITIES_TO_DISPLAY,
  SEO_TYPES,
  SEO_TYPES_TO_DISPLAY,
  SEO_ENTITIES,
} from '@/constants/seo'

export default {
  components: { ActionButtons, Autocomplete, SeoBlock, Dropdown },
  MODAL_TYPES,
  SORTING_TYPES,
  SEO_ENTITIES_TO_DISPLAY,
  SEO_TYPES,
  SEO_TYPES_TO_DISPLAY,
  SEO_ENTITIES,
  mixins: [notifications],
  data() {
    return {
      isFiltersOpen: false,
      isModalOpen: false,
      currentModalType: MODAL_TYPES.CREATE,
      seos: [],
      count: 0,
      currentSeoId: '',
      form: {
        seo: {
          title: '',
          description: '',
          type: '',
          entity: null,
          keywords: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: {
            id: null,
            caption: '',
            origin: '',
            order: 1,
          },
        },
      },
      filters: {
        type: null,
        entity: null,
      },
      query: {
        page: 1,
        limit: 20,
        orderBy: 'name',
        orderDir: SORTING_TYPES.ASC,
        search: '',
      },
      rules: {
        seo: {
          entity: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          keywords: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: {
            origin: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'change',
              },
            ],
          },
        },
      },
    }
  },
  computed: {
    totalPage() {
      return Math.ceil(this.count / this.query.limit)
    },
  },
  async created() {
    await this.getSeosList()
  },
  methods: {
    async submitFilters() {
      this.isFiltersOpen = false

      await this.getSeosList()
    },
    resetStateFilters() {
      this.filters = {
        type: null,
        entity: null,
      }
    },
    async resetFilters() {
      this.isFiltersOpen = false

      this.resetStateFilters()
      await this.getSeosList()
    },
    async getSeosList() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.SeoTemplatesActions.getList({
          ...this.query,
          ...this.filters,
        })

      loading.close()

      if (error) return

      this.seos = value?.data
      this.count = value?.meta?.count
    },
    async getSeo(id) {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.SeoTemplatesActions.getOne(id)

      loading.close()

      if (error) {
        this.showNotification('Ошибка получения seo-шаблона', 'error')
      }

      this.form = {
        seo: value
          ? {
              ...value,
              ogImage: value?.ogImage
                ? {
                    ...value?.ogImage,
                    origin: value?.ogImage?.original,
                  }
                : this.form?.seo?.ogImage,
            }
          : this.form?.seo,
      }
    },
    async createSeo() {
      const loading = this.$loading({
        lock: true,
      })

      const { error } =
        await delivery.AddeventServiceCore.SeoTemplatesActions.create({
          ...this.form?.seo,
          type: SEO_TYPES.TEMPLATE,
          ogImage: {
            ...this.form?.seo?.ogImage,
            caption: this.form?.seo?.title,
          },
        })

      loading.close()

      if (error)
        return error?.includes('TEMPLATE ALREADY EXISTS')
          ? this.showNotification(
              'Для данной сущности уже есть seo-шаблон',
              'error',
            )
          : this.showNotification('Ошибка создания seo-шаблона', 'error')

      this.showNotification('Seo-шаблон успешно создан', 'success')
    },
    async updateSeo() {
      const loading = this.$loading({
        lock: true,
      })

      const { error } =
        await delivery.AddeventServiceCore.SeoTemplatesActions.update(
          {
            ...this.form?.seo,
            type: this.form?.seo?.type ?? SEO_TYPES.TEMPLATE,
            ogImage: {
              ...this.form?.seo?.ogImage,
              caption: this.form?.seo?.title,
            },
          },
          this.currentSeoId,
        )

      loading.close()

      if (error)
        return error?.includes('TEMPLATE ALREADY EXISTS')
          ? this.showNotification(
              'Для данной сущности уже есть seo-шаблон',
              'error',
            )
          : this.showNotification('Ошибка обновления seo-шаблона', 'error')

      this.showNotification('Seo-шаблон успешно обновлен', 'success')
    },
    async deleteSeo(seo) {
      const isConfirm = confirm(
        `Вы точно хотите удалить seo-шаблона: ${seo.title}`,
      )

      if (!isConfirm) return

      const loading = this.$loading({
        lock: true,
      })

      const { error } =
        await delivery.AddeventServiceCore.SeoTemplatesActions.delete(seo.id)

      loading.close()

      if (error) {
        this.showNotification('Ошибка удаления seo-шаблона', 'error')
        return
      }

      this.showNotification('Seo-шаблон успешно удален', 'success')
      await this.getSeosList()
    },
    handleCreateSeo() {
      this.isModalOpen = true
      this.currentModalType = MODAL_TYPES.CREATE
    },
    async handleUpdateSeo(id) {
      this.currentModalType = MODAL_TYPES.UPDATE
      this.isModalOpen = true
      this.currentSeoId = id

      await this.getSeo(id)
    },
    resetForm() {
      this.form = {
        seo: {
          title: '',
          description: '',
          type: '',
          entity: null,
          keywords: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: {
            id: null,
            caption: '',
            origin: '',
            order: 1,
          },
        },
      }
    },
    handleCloseForm() {
      this.isModalOpen = false
      this.resetForm()
    },
    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        this.currentModalType === MODAL_TYPES.CREATE
          ? await this.createSeo()
          : await this.updateSeo()

        this.resetForm()
        this.isModalOpen = false
        await this.getSeosList()
      })
    },
    async querySearch({ queryString, setSearchItems }) {
      const { value, error } =
        await delivery.AddeventServiceCore.SeoTemplatesActions.getList({
          limit: 100,
          page: this.query.page,
          search: queryString,
        })

      if (error) return

      setSearchItems(value.data)
    },
  },
}
</script>
<style lang="scss" module>
.page {
  padding: 1rem;

  .wrapper {
    @include stickyWrapper;
    justify-content: space-between;
    .container {
      display: flex;
      gap: 1rem;

      .filters {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .dropdown {
          left: 7rem;
          top: 0;
          & > div {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .filter {
              display: grid;
              align-items: center;
              grid-template-columns: 1fr 2fr;
              gap: 0.5rem;
            }
          }
        }
      }
    }
  }

  .pagination {
    @include pagination;
  }

  .dialog {
    .dialogInfo {
      word-break: keep-all;
      text-align: center;
      margin-bottom: 1.5rem;
    }
    .createButton {
      margin-top: 1rem;
    }
  }
}
</style>
